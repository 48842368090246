import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { initialState, nameState } from './types'

const politicallyExposedPersonlice = createSlice({
  name: nameState,
  initialState,
  reducers: {
    fetchPepInit(state) {
      return {
        ...state,
        pepLoading: true,
        pepError: undefined,
        pepSuccess: undefined,
        pepFail: undefined,
      }
    },

    fetchPepSuccess(state, action: PayloadAction<any>) {
      return {
        ...state,
        ...action.payload,
        pepLoading: false,
        pepError: undefined,
        pepSuccess: true,
        pepFail: false,
      }
    },

    fetchPepFail(state) {
      return {
        ...state,
        pepLoading: false,
        pepError: undefined,
        pepSuccess: false,
        pepFail: true,
      }
    },

    fetchPepError(state, action: PayloadAction<any>) {
      return {
        ...state,
        pepLoading: false,
        pepError: action.payload,
        pepSuccess: false,
        pepFail: false,
      }
    },

    setLoan(state) {
      return state
    },

    deletePep() {
      return initialState
    },
  },
})

export const {
  fetchPepInit,
  fetchPepSuccess,
  fetchPepFail,
  fetchPepError,
  deletePep,
} = politicallyExposedPersonlice.actions
export default politicallyExposedPersonlice.reducer
