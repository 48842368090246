/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { lazy, Suspense } from 'react'

import { ModuleService } from '@services/module'
import { IModuleResponse } from '@resources/types/services/module'
import { applyModuleStructure } from '@store/module/structure/slice'
import { applyModuleTheme } from '@store/module/theme/slice'
import { applyModuleBackend } from '@store/module/backend/slice'
import { store } from '@store/index'
import { prepareThemeToStoreApply } from '@resources/utils/theme'
import { getSessioStorage, clearStorage, clearStore } from '@store/index'

import Loading from './Loading'
import { cookieVariabels, removeCookie } from '@resources/helpers/cookie'

const getThemeFromStorage = async () => {
  const themeFromStorage = (await getSessioStorage()) || ''

  if (!themeFromStorage) {
    return {}
  }

  const storeStorage = JSON.parse(themeFromStorage)
  const { theme } = JSON.parse(storeStorage.moduleTheme)

  return theme
}

const App = lazy(async (): Promise<any> => {
  const user = store.getState().user
  const { structure } = store.getState().moduleStructure

  if (!structure?.theme?.version && !user.document) {
    clearStore()
    removeCookie(cookieVariabels.systemSession)
    await clearStorage()
  }

  const dataConfig = await ModuleService.get()

  if (dataConfig.status === 200 && !user.document) {
    const module = dataConfig.data as IModuleResponse
    const { preparedTheme } = prepareThemeToStoreApply(module.theme)

    store.dispatch(applyModuleTheme(preparedTheme))
    store.dispatch(applyModuleStructure(module.structure))
    store.dispatch(applyModuleBackend(module.backend))
  }

  const getRecursiveImportApp = async (resolve: any) => {
    const theme = await getThemeFromStorage()

    if (
      !theme?.defaultPallete?.title &&
      !theme.pallete?.login?.footer?.background
    ) {
      setTimeout(() => getRecursiveImportApp(resolve), 1500)
      return
    }

    resolve(import('./app'))
  }

  return new Promise((resolve) => {
    getRecursiveImportApp(resolve)
  })
})

const AppView: React.FC = () => {
  return <Suspense fallback={<Loading />}>{<App />}</Suspense>
}

export default AppView
