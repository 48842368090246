import { ILoanDetailsResponseV3 } from '@resources/types/services/loan'

export const nameState = 'loanV3'

export interface ILoanStateV3 extends ILoanDetailsResponseV3 {
  loanLoading: boolean
  loanError?: Error
  loanSuccess?: boolean
}

export const initialState: ILoanStateV3 = {
  canSimulateLoan: false,
  podeAmortizar: false,
  reasonWhyCantSimulateLoan: '',
  canRequestLoan: false,
  reasonWhyCantRequestLoan: '',
  loanTypes: [],
  account: {
    bank: '',
    branchNumber: '',
    accountNumber: '',
    bankId: 0,
    agencyId: 0,
  },
  loans: [],
  loanLoading: true,
  loanError: undefined,
  loanSuccess: undefined,
}
