import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { initialState, nameState } from './types'

const loanSimulationSlice = createSlice({
  name: nameState,
  initialState,
  reducers: {
    fetchLoanSimulationInit(state) {
      return {
        ...state,
        loanSimulationLoading: true,
        loanSimulationError: undefined,
        loanSimulationSuccess: undefined,
        loanSimulationFail: undefined,
      }
    },

    fetchLoanSimulationSuccess(state, action: PayloadAction<any>) {
      return {
        ...state,
        ...action.payload,
        loanSimulationLoading: false,
        loanSimulationError: undefined,
        loanSimulationSuccess: true,
        loanSimulationFail: false,
      }
    },

    fetchLoanSimulationFail(state) {
      return {
        ...state,
        loanSimulationLoading: false,
        loanSimulationError: undefined,
        loanSimulationSuccess: false,
        loanSimulationFail: true,
      }
    },

    fetchLoanSimulationError(state, action: PayloadAction<any>) {
      return {
        ...state,
        loanSimulationLoading: false,
        loanSimulationError: action.payload,
        loanSimulationSuccess: false,
        loanSimulationFail: false,
      }
    },

    setLoanSimulation(state) {
      return state
    },

    deleteLoanSimulation() {
      return initialState
    },
  },
})

export const {
  fetchLoanSimulationInit,
  fetchLoanSimulationSuccess,
  fetchLoanSimulationFail,
  fetchLoanSimulationError,
  deleteLoanSimulation,
} = loanSimulationSlice.actions

export default loanSimulationSlice.reducer
