import { configureStore, combineReducers } from '@reduxjs/toolkit'
// import storage from 'redux-persist/lib/storage'
import storageSession from 'redux-persist/lib/storage/session'
import { persistReducer, persistStore } from 'redux-persist'
import { Reducer } from 'redux'
import thunk from 'redux-thunk'

import userReducer, { deleteUser } from '@store/user/slice'
import authReducer, { deleteAuth } from '@store/auth/slice'
import loanReducer from '@store/loan/slice'
import loanV2Reducer from '@store/loanV2/slice'
import loanReducerV3 from '@store/loanV3/slice'
import loanSimulationReducer from '@store/loanSimulation/slice'
import loanSimulationDemoReducer from '@store/loanSimulationDemo/slice'
import loanSimulationReducerV3 from '@store/loanSimulationV3/slice'
import isActive2FAUserReducer from '@store/infos/isActive2FAUser/slice'
import loanTermsReducer from '@store/loanTerms/slice'
import contribuitionReducer from '@store/contribuition/slice'
import politicallyExposedPersonReducer from '@store/politicallyExposedPerson/slice'
import loanTaxesReducer from '@store/loanTaxes/slice'
import planSelectedReducer from '@store/planSelected/slice'
import moduleStructureReducer, {
  deleteModuleStructure,
} from '@store/module/structure/slice'
import moduleThemeReducer, {
  deleteModuleTheme,
} from '@store/module/theme/slice'
import moduleBackendReducer, {
  deleteModuleBackend,
} from '@store/module/backend/slice'
import { clearStorage as clearSessionStorage } from '@resources/utils/sessionStorage'

const reducers = combineReducers({
  user: userReducer,
  auth: authReducer,
  loan: loanReducer,
  loanV2: loanV2Reducer,
  loanV3: loanReducerV3,
  loanSimulation: loanSimulationReducer,
  loanSimulationDemo: loanSimulationDemoReducer,
  loanSimulationV3: loanSimulationReducerV3,
  loanTerms: loanTermsReducer,
  contribuition: contribuitionReducer,
  politicallyExposedPerson: politicallyExposedPersonReducer,
  loanTaxes: loanTaxesReducer,
  planSelected: planSelectedReducer,
  moduleStructure: moduleStructureReducer,
  moduleTheme: moduleThemeReducer,
  moduleBackend: moduleBackendReducer,
  isActive2FAUser: isActive2FAUserReducer,
})

const persistConfig = {
  key: 'uFund',
  storage: storageSession,
  whitelist: [
    'user',
    'auth',
    'moduleTheme',
    'moduleStructure',
    'moduleBackend',
  ],
}

const persistedReducer: Reducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
})

export const persistor = persistStore(store)

export const clearStorage = async () => {
  const clear = await Promise.all([localStorage.clear(), clearSessionStorage()])

  return clear
}

export const removeStorage = async (key = 'uFund') => {
  sessionStorage.removeItem(`persist:${key}`)
}

export const getStorage = async (key = 'uFund') => {
  return sessionStorage.getItem(`persist:${key}`)
}

export const getSessioStorage = async (key = 'uFund') => {
  return sessionStorage.getItem(`persist:${key}`)
}

export const getLocalStorage = (key = 'uFund') => {
  return sessionStorage.getItem(`persist:${key}`)
}

export const clearStore = () => {
  store.dispatch(deleteUser())
  store.dispatch(deleteAuth())
  store.dispatch(deleteModuleStructure())
  store.dispatch(deleteModuleTheme())
  store.dispatch(deleteModuleBackend())
}
