import { ILoanTaxesResponse } from '@resources/types/services/loan'

export const nameState = 'loanTaxes'

export interface IloanTaxesState extends ILoanTaxesResponse {
  loanTaxesLoading: boolean
  loanTaxesError?: Error
  loanTaxesSuccess?: boolean
}

export const initialState: IloanTaxesState = {
  administrationFee: '',
  annualInterest: '',
  concessionFee: '',
  creditedAmount: '',
  guaranteeBookingFee: '',
  iof: '',
  monthlyCet: '',
  renewalValue: '',
  totalDiscounts: '',
  totalInterestRate: '',
  yearlyCet: '',

  loanTaxesLoading: false,
  loanTaxesError: undefined,
  loanTaxesSuccess: undefined,
}
