import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { initialState, nameState } from './types'

const planSelectedSlice = createSlice({
  name: nameState,
  initialState,
  reducers: {
    changePlanSelected(state, action: PayloadAction<any>) {
      return {
        ...state,
        ...action.payload,
      }
    },

    setPlanSelected(state) {
      return state
    },

    deletePlanSelected() {
      return initialState
    },
  },
})

export const { changePlanSelected, setPlanSelected, deletePlanSelected } =
  planSelectedSlice.actions

export default planSelectedSlice.reducer
