import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { IModuleStructure } from '@resources/types/services/module'
import { initialState, nameState } from './types'

const moduleStructureSlice = createSlice({
  name: nameState,
  initialState,
  reducers: {
    applyModuleStructure(state, action: PayloadAction<IModuleStructure>) {
      return {
        ...state,
        structure: { ...action.payload },
      }
    },

    updateStructureResources(state, action: PayloadAction<any>) {
      return {
        ...state,
        structure: {
          ...state.structure,
          contacts: { ...action.payload.structure.contacts },
          resources: { ...action.payload.structure.resources },
        },
      }
    },

    deleteModuleStructure() {
      return initialState
    },
  },
})

export const {
  applyModuleStructure,
  deleteModuleStructure,
  updateStructureResources,
} = moduleStructureSlice.actions

export default moduleStructureSlice.reducer
