import {
  IModuleThemeResponse,
  IModuleTheme,
} from '@resources/types/services/module'
import { populateStorage } from '@resources/utils/sessionStorage'

export const prepareThemeToStoreApply = (
  theme: IModuleThemeResponse,
): { defaultStyle: string; preparedTheme: IModuleTheme } => {
  const defaultStyle = theme.defaultStyle
  const selectedTheme = theme.defaultpallete[defaultStyle]

  const preparedTheme: IModuleTheme = {
    defaultPallete: theme.defaultpallete.default as any,
    pallete: selectedTheme,
  }

  const preparedThemeInString = JSON.stringify(preparedTheme)
  populateStorage('theme:uFund', preparedThemeInString)

  return { defaultStyle, preparedTheme }
}
