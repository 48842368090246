import { IModuleStructure } from '@resources/types/services/module'

export const nameState = 'moduleStructure'

export interface IModuleStructureState {
  structure: IModuleStructure
}

export const initialState: IModuleStructureState = {
  structure: {} as IModuleStructure,
}
