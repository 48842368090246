import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { initialState, nameState } from './types'

const loanTaxesSlice = createSlice({
  name: nameState,
  initialState,
  reducers: {
    fetchLoanTaxesInit(state) {
      return {
        ...state,
        loanTaxesLoading: true,
        loanTaxesError: undefined,
        loanTaxesSuccess: undefined,
        loanTaxesFail: undefined,
      }
    },

    fetchLoanTaxesSuccess(state, action: PayloadAction<any>) {
      return {
        ...state,
        ...action.payload,
        loanTaxesLoading: false,
        loanTaxesError: undefined,
        loanTaxesSuccess: true,
        loanTaxesFail: false,
      }
    },

    fetchLoanTaxesFail(state) {
      return {
        ...state,
        loanTaxesLoading: false,
        loanTaxesError: undefined,
        loanTaxesSuccess: false,
        loanTaxesFail: true,
      }
    },

    fetchLoanTaxesError(state, action: PayloadAction<any>) {
      return {
        ...state,
        loanTaxesLoading: false,
        loanTaxesError: action.payload,
        loanTaxesSuccess: false,
        loanTaxesFail: false,
      }
    },

    setLoanTaxes(state) {
      return state
    },

    deleteLoanTaxes() {
      return initialState
    },
  },
})

export const {
  fetchLoanTaxesInit,
  fetchLoanTaxesSuccess,
  fetchLoanTaxesFail,
  fetchLoanTaxesError,
  deleteLoanTaxes,
} = loanTaxesSlice.actions

export default loanTaxesSlice.reducer
