import { IUserResponse } from '@resources/types/services/user'

export const nameState = 'user'

export interface IUserState extends IUserResponse {
  userLoading: boolean
  userError?: Error | undefined
  userSuccess?: boolean | undefined
}

export const initialState: IUserState = {
  document: '',
  name: '',
  birthday: '',
  gender: '',
  phone: '',
  email: '',
  balance: '',
  dateBalance: '',
  totalUserContribution: '',
  totalCompanyContribution: '',
  monthlyUserContribution: '',
  incorporationStatus: '',
  canDoLoan: true,
  canShowContributions: true,
  isDependent: false,
  mfaActiveFoundation: false,
  mfaActiveUser: false,
  showBeneficiares: false,
  showIncomeSimulator: false,
  showIrDependents: false,
  plans: [],

  userLoading: false,
  userError: undefined,
  userSuccess: undefined,
  situation: '',
}
