import { ILoanTermsResponse } from '@resources/types/services/loan'

export const nameState = 'loanTerms'

export interface IloanTermsState extends ILoanTermsResponse {
  loanTermsLoading: boolean
  loanTermsError?: Error
  loanTermsSuccess?: boolean
}

export const initialState: IloanTermsState = {
  generalConditions: {
    url: '',
    id: '',
    type: '',
    version: 0,
    description: '',
  },
  privacyPolicy: {
    url: '',
    id: '',
    type: '',
    version: 0,
  },
  terms: {
    generalConditions: {
      id: '',
      type: '',
      description: '',
    },
  },

  loanTermsLoading: false,
  loanTermsError: undefined,
  loanTermsSuccess: undefined,
}
