import { ClipLoader } from 'react-spinners'

import { WrapperLoading, Text } from './styles'

const LoadingView = () => {
  return (
    <WrapperLoading>
      <ClipLoader size={50} color={'#303134'} loading={true} />
      <Text>
        Bem-vindo!
        <br /> Estamos carregando. Aguarde...
      </Text>
    </WrapperLoading>
  )
}

export default LoadingView
