import { IModuleBackend } from '@resources/types/services/module'

export const nameState = 'moduleBackend'

export interface IModuleBackendState {
  backend: IModuleBackend
}

export const initialState: IModuleBackendState = {
  backend: {} as IModuleBackend,
}
