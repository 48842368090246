import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { initialState, nameState } from './types'

const loanSlice = createSlice({
  name: nameState,
  initialState,
  reducers: {
    fetchLoanInit(state) {
      return {
        ...state,
        loanLoading: true,
        loanError: undefined,
        loanSuccess: undefined,
        loanFail: undefined,
      }
    },

    fetchLoanSuccess(state, action: PayloadAction<any>) {
      return {
        ...state,
        ...action.payload,
        loanLoading: false,
        loanError: undefined,
        loanSuccess: true,
        loanFail: false,
      }
    },

    fetchLoanFail(state) {
      return {
        ...state,
        loanLoading: false,
        loanError: undefined,
        loanSuccess: false,
        loanFail: true,
      }
    },

    fetchLoanError(state, action: PayloadAction<any>) {
      return {
        ...state,
        loanLoading: false,
        loanError: action.payload,
        loanSuccess: false,
        loanFail: false,
      }
    },

    setLoan(state) {
      return state
    },

    deleteLoan() {
      return initialState
    },
  },
})

export const {
  fetchLoanInit,
  fetchLoanSuccess,
  fetchLoanFail,
  fetchLoanError,
  deleteLoan,
} = loanSlice.actions
export default loanSlice.reducer
