import { UserType } from '@resources/models/user'
import { AuthCredentials } from '@resources/models/auth'

export const nameState = 'auth'

export interface IAuthState {
  credentials?: AuthCredentials
  userType?: UserType
  signin2Fa: {
    mfaActive: boolean
    mfaType: string[]
    email: string
    phone: string
  }
  signinLoading: boolean
  signinError?: Error
  signinSuccess?: boolean
  signinFail?: boolean
}

export const initialState: IAuthState = {
  credentials: undefined,
  userType: undefined,
  signin2Fa: {
    mfaActive: false,
    mfaType: [''],
    email: '',
    phone: '',
  },
  signinLoading: false,
  signinError: undefined,
  signinSuccess: undefined,
  signinFail: undefined,
}
