export const nameState = 'loanSimulationDemo'

interface IInstallment {
  document_number: number
  installment_date: string
  installment_amount: string
  installment_rate: string
  amortize_value: string
  value_remaining: string
  debit_balance: string
  date_dif: number
  iof_basic: string
  iof_additional: string
  tot_iof: string
  grace_period_rate: string
}

export interface IInitialStateSimulation {
  debit_balance: string
  requested_amount: string
  financed_amount: string
  disbursement_amount: string
  rate: string
  rate_yearly: string
  rate_amount: string
  grace_period_rate_amount: string
  requested_date: string
  number_installments: number
  days_to_first_due_date: number
  grace_period: number
  installment_amount: string
  installment_frequency: string
  financed_iof: string
  amount_iof: string
  amount_iof_additional: string
  percentual_iof_additional: number
  amount_iof_daily: string
  percentual_iof_daily: number
  cet_monthly: string
  cet_yearly: string
  unfinanced_charge: string
  financed_charge: string
  first_date_due: string
  last_date_due: string
  loanSimulationLoading?: boolean
  loanSimulationError?: boolean
  loanSimulationSuccess?: boolean
  installments: IInstallment[]
}

export const initialState: IInitialStateSimulation = {
  debit_balance: '',
  requested_amount: '',
  financed_amount: '',
  disbursement_amount: '',
  rate: '',
  rate_yearly: '',
  rate_amount: '',
  grace_period_rate_amount: '',
  requested_date: '',
  number_installments: 0,
  days_to_first_due_date: 0,
  grace_period: 0,
  installment_amount: '',
  installment_frequency: '',
  financed_iof: '',
  amount_iof: '',
  amount_iof_additional: '',
  percentual_iof_additional: 0,
  amount_iof_daily: '',
  percentual_iof_daily: 0,
  cet_monthly: '',
  cet_yearly: '',
  unfinanced_charge: '',
  financed_charge: '',
  first_date_due: '',
  last_date_due: '',
  installments: [],
  loanSimulationLoading: false,
  loanSimulationError: undefined,
  loanSimulationSuccess: undefined,
}
